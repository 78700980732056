import './bootstrap';
import '../css/app.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { RouteContext } from '@/Hooks/useRoute';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import.meta.glob(['../images/**', '../fonts/**']);

createInertiaApp({
  progress: {
    color: 'var(--color-progress)',
  },
  resolve: name => {
    if (typeof name === 'undefined') {
      name = 'Errors/Error';
    }
    return resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob(['./Pages/**/*.tsx']),
    );
  },
  setup({ el, App, props }) {
    const root = createRoot(el);
    return root.render(
      <RouteContext.Provider value={(window as any).route}>
        <App {...props} />
      </RouteContext.Provider>,
    );
  },
});
